<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-setcode-http-new" novalidate>
            <div v-if="errors.length" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.activeLang }
                )
              }}
            </div>
            <div class="col-md-12">
              <h3>
                {{
                  $t(
                    "HttpConnections.NewTitle",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </h3>
            </div>
            <div class="col-md-6">
              <label for="Name" class="form-label required"
                >{{
                  $t(
                    "HttpConnections.Name",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="Name"
                @keyup="functionNameKeyup()"
                v-model="setCodeHttpData.name"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="FormulaName" class="form-label required">{{
                $t(
                  "BaseModelFields.FormulaName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control text-uppercase"
                id="FormulaName"
                @keyup="formulaNameKeyup()"
                v-model="setCodeHttpData.formulaName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-12">
              <label for="Description" class="form-label required"
                >{{
                  $t(
                    "BaseModelFields.Description",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <textarea
                class="form-control"
                id="Description"
                v-model="setCodeHttpData.description"
                :spellcheck="this.$isTextSpellCheck"
                required
              ></textarea>
            </div>
            <div class="col col-md-6">
              <label for="Method" class="form-label required">{{
                $t(
                  "BusinessRuleActions.Method",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :allowEmpty="false"
                :selectedData="selectedMethod"
                :data="methodData"
                :key="selectedMethod[0].key"
                v-model="setCodeHttpData.methodData"
                @onChange="methodChanged"
              />
            </div>
            <div class="col col-md-6">
              <label for="ContentType" class="form-label required">{{
                $t(
                  "BusinessRuleActions.HTTPContent",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :allowEmpty="false"
                :selectedData="selectedContent"
                :data="contentData"
                :key="selectedContent[0].key"
                v-model="setCodeHttpData.contentData"
                @onChange="contentChanged"
              />
            </div>
            <div class="col col-md-12" v-if="isGetMethodSelected()">
              <label for="BodyParameterName" class="form-label">{{
                $t(
                  "HttpConnections.BodyParameterName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="text"
                class="form-control"
                id="BodyParameterName"
                v-model="setCodeHttpData.bodyParameterName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col col-md-6">
              <label for="ReturnType" class="form-label required">{{
                $t(
                  "HttpConnections.ReturnType",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :allowEmpty="false"
                :selectedData="selectedReturnType"
                :data="returnTypeData"
                :key="selectedReturnType[0].key"
                v-model="setCodeHttpData.returnTypeData"
                @onChange="returnTypeChanged"
              />
            </div>
            <div class="col col-md-6" v-if="isGetSystemVariable()">
              <label for="SystemVariables" class="form-label required">{{
                $t(
                  "HttpConnections.SystemVariables",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :allowEmpty="false"
                :selectedData="selectedSystemVariable"
                :data="systemVariableData"
                :key="selectedSystemVariable[0].key"
                v-model="setCodeHttpData.systemVariableData"
                @onChange="systemVariableChanged"
              />
            </div>
            <div class="col col-md-6" v-if="isGetCustomModel()">
              <label for="CustomModel" class="form-label required">{{
                $t(
                  "HttpConnections.CustomModel",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithRemoteData"
                @onChange="customModelChanged"
                :selectedData="selectedCustomModel"
                :allowEmpty="false"
                :isGatewayRequest="true"
                requestUrl="/Brs-SetCodeModelGetSetCodeModels"
              />
            </div>
            <div class="col col-md-12 mt-3">
              <label for="HTTPRequestAdress" class="form-label required">{{
                $t(
                  "BusinessRuleActions.HTTPRequestAdress",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <textarea
                name="HTTPRequestAdress"
                class="form-control"
                id="HTTPRequestAdress"
                v-model="setCodeHttpData.httpRequestAddress"
                rows="2"
                required
              ></textarea>
            </div>
            <div class="col-md-6">
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="setCodeHttpData.isActive"
                />
              </div>
            </div>
            <div class="mt-4">
              <label for="HTTPHeaders" class="form-label required">
                {{
                  $t(
                    "HttpConnections.HTTPHeaders",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <HttpConnectionTable
                :data="null"
                :customObjectPublicId="this.$route.params.customObjectId"
                @setTableItems="getTableItems"
              />
            </div>
            <ActionButtons
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import HttpConnectionTable from "../../../components/custom/setcode/httpConnection/HttpConnectionTable.vue";
export default {
  name: "HttpConnectionNew",
  components: { HttpConnectionTable },
  data() {
    return {
      methodData: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Get",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "BusinessRuleActions.Post",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedMethod: [
        {
          key: 1,
          value: this.$t(
            "BusinessRuleActions.Get",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      contentData: [
        {
          key: 1,
          value: this.$t(
            "HttpConnections.Json",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "HttpConnections.Xml",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedContent: [
        {
          key: 1,
          value: this.$t(
            "HttpConnections.Json",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      returnTypeData: [
        {
          key: 1,
          value: this.$t(
            "HttpConnections.SystemVariables",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "HttpConnections.CustomModel",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedReturnType: [
        {
          key: 1,
          value: this.$t(
            "HttpConnections.SystemVariables",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      systemVariableData: [
        {
          key: 1,
          value: this.$t(
            "Configurations.Integer",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "Configurations.Number",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 3,
          value: this.$t(
            "Configurations.Decimal",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 4,
          value: this.$t(
            "Configurations.String",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 5,
          value: this.$t(
            "Configurations.Date",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 6,
          value: this.$t(
            "Configurations.DateTime",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 7,
          value: this.$t(
            "Configurations.Boolean",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedSystemVariable: [
        {
          key: 1,
          value: this.$t(
            "Configurations.Integer",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedCustomModel: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      modelType: null,
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      httpHeaderValues: [],
      setCodeHttpData: {
        isActive: true,
      },
      isSuccess: false,
    };
  },
  methods: {
    getTableItems(tableItems) {
      this.setCodeHttpData.httpHeaderValues = tableItems;
    },
    onClear() {
      this.setCodeHttpData = {
        isActive: true,
      };
      $(".form-setcode-http-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-setcode-http-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }
      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }
      this.errors = [];
      this.$prodGatewayAxios
        .post("/Brs-SetCodeHttpConnectionNew", { ...this.setCodeHttpData })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/SetCode/HttpConnection/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
    formulaNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.setCodeHttpData.name)) {
        this.setCodeHttpData.formulaName = "";
        return;
      }
      this.setCodeHttpData.formulaName = String.replaceAlphanumeric(
        this.setCodeHttpData.name,
        "_"
      ).toUpperCase();
    },
    functionNameKeyup: function () {
      if (String.isNullOrWhiteSpace(this.setCodeHttpData.name)) {
        this.setCodeHttpData.formulaName = "";
        return;
      }
      this.setCodeHttpData.formulaName = String.replaceAlphanumeric(
        this.setCodeHttpData.name,
        "_"
      ).toUpperCase();
    },
    isGetMethodSelected() {
      if (this.selectedMethod && this.selectedMethod.length > 0) {
        return this.selectedMethod[0].key === 2;
      }
      return false;
    },
    isGetSystemVariable() {
      if (this.selectedReturnType && this.selectedReturnType.length > 0) {
        return this.selectedReturnType[0].key === 1;
      }
      return false;
    },
    isGetCustomModel() {
      if (this.selectedReturnType && this.selectedReturnType.length > 0) {
        return this.selectedReturnType[0].key === 2;
      }
      return false;
    },
    methodChanged(selectedData) {
      this.setCodeHttpData.methodText = selectedData.value;
      this.setCodeHttpData.methodId = selectedData.key;
      this.selectedMethod = [selectedData];
    },
    contentChanged(selectedData) {
      this.setCodeHttpData.httpContentTypeText = selectedData.value;
      this.setCodeHttpData.httpContentTypeId = selectedData.key;
      this.selectedContent = [selectedData];
    },
    returnTypeChanged(selectedData) {
      this.setCodeHttpData.returnTypeName = selectedData.value;
      this.setCodeHttpData.returnTypeId = selectedData.key;
      this.selectedReturnType = [selectedData];

      if (selectedData.key === 1) {
        this.setCodeHttpData.modelName = "";
        this.setCodeHttpData.modelPublicId = "";
        this.setCodeHttpData.systemVariableName =
          this.selectedSystemVariable[0].value;
        this.setCodeHttpData.systemVariableId =
          this.selectedSystemVariable[0].key;
      } else if (selectedData.key === 2) {
        this.setCodeHttpData.systemVariableName = "";
        this.setCodeHttpData.systemVariableId = 0;
      } else if (selectedData.key === null) {
        this.setCodeHttpData.systemVariableName = "";
        this.setCodeHttpData.systemVariableId = 0;
        this.setCodeHttpData.modelName = "";
        this.setCodeHttpData.modelPublicId = "";
      }
    },
    systemVariableChanged(selectedData) {
      this.setCodeHttpData.systemVariableName = selectedData.value;
      this.setCodeHttpData.systemVariableId = selectedData.key;
      this.selectedSystemVariable = [selectedData];
    },
    customModelChanged(selectedData) {
      this.setCodeHttpData.modelName = selectedData.value;
      this.setCodeHttpData.modelPublicId = selectedData.key;
      this.selectedCustomModel = [selectedData];
    },
  },
  watch: {
    selectedMethod: {
      handler(newVal, oldVal) {
        if (newVal && newVal.length > 0 && newVal[0].key === 1) {
          this.setCodeHttpData.bodyParameterName = "";
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.methodChanged(this.selectedMethod[0]);
    this.contentChanged(this.selectedContent[0]);
    this.returnTypeChanged(this.selectedReturnType[0]);
    this.systemVariableChanged(this.selectedSystemVariable[0]);
  },
};
</script>
